import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { isUserAuthorized } from '../../common/Authorization';
import { UserTypes } from '../../common/UserTypes';

const NotFound = lazy(() => import('../../container/pages/404'));
const TopupRequestsList = lazy(() => import('../../container/wallet'));
const MoneyFlowList = lazy(() => import('../../container/moneyFlow'));
const AddManualCredit = lazy(() => import('../../container/moneyFlow/components/AddManualCredit'));
const ManualDebt = lazy(() => import('../../container/moneyFlow/components/ManualDebt'));
const TopupRequestDetails = lazy(() => import('../../container/wallet/TopupRequestDetails'));

function MerchantGroupsRoute() {
  return (
    <Routes>
      <Route path="/" element={<TopupRequestsList />} />
      <Route path="/topup-requests" element={<TopupRequestsList />} />
      <Route path="/topup-requests/:id" element={<TopupRequestDetails />} />
      <Route path="/money-flow" element={<MoneyFlowList />} />
      {isUserAuthorized([UserTypes.DASHBOARD_ADMIN_ID, UserTypes.DASHBOARD_USER_ID]) && (
        <Route path="/money-flow/credit" element={<AddManualCredit />} />
      )}
      {isUserAuthorized([UserTypes.DASHBOARD_ADMIN_ID, UserTypes.DASHBOARD_USER_ID]) && (
        <Route path="/money-flow/debt" element={<ManualDebt />} />
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default MerchantGroupsRoute;
