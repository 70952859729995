const actions = {
  // MONY FLOW ACTIONS
  LIST_WALLET_TRANSACTIONS_BEGIN: 'LIST_WALLET_TRANSACTIONS_BEGIN',
  LIST_WALLET_TRANSACTIONS_SUCCESS: 'LIST_WALLET_TRANSACTIONS_SUCCESS',
  LIST_WALLET_TRANSACTIONS_ERR: 'LIST_WALLET_TRANSACTIONS_ERR',

  GET_WALLET_BALANCE_BEGIN: 'GET_WALLET_BALANCE_BEGIN',
  GET_WALLET_BALANCE_SUCCESS: 'GET_WALLET_BALANCE_SUCCESS',
  GET_WALLET_BALANCE_ERR: 'GET_WALLET_BALANCE_ERR',

  // TOP-UPS
  LIST_TOPUP_REQUEST_BEGIN: 'LIST_TOPUP_REQUEST_BEGIN',
  LIST_TOPUP_REQUEST_SUCCESS: 'LIST_TOPUP_REQUEST_SUCCESS',
  LIST_TOPUP_REQUEST_ERR: 'LIST_TOPUP_REQUEST_ERR',

  GET_TOPUP_REQUEST_DETAILS_BEGIN: 'GET_TOPUP_REQUEST_DETAILS_BEGIN',
  GET_TOPUP_REQUEST_DETAILS_SUCCESS: 'GET_TOPUP_REQUEST_DETAILS_SUCCESS',
  GET_TOPUP_REQUEST_DETAILS_ERR: 'GET_TOPUP_REQUEST_DETAILS_ERR',

  ADD_MANUAL_CREDIT_BEGIN: 'ADD_MANUAL_CREDIT_BEGIN',
  ADD_MANUAL_CREDIT_SUCCESS: 'ADD_MANUAL_CREDIT_SUCCESS',
  ADD_MANUAL_CREDIT_ERR: 'ADD_MANUAL_CREDIT_ERR',

  ADD_MANUAL_DEBT_BEGIN: 'ADD_MANUAL_DEBT_BEGIN',
  ADD_MANUAL_DEBT_SUCCESS: 'ADD_MANUAL_DEBT_SUCCESS',
  ADD_MANUAL_DEBT_ERR: 'ADD_MANUAL_DEBT_ERR',

  listWalletTransactionsBegin: () => {
    return {
      type: actions.LIST_WALLET_TRANSACTIONS_BEGIN,
    };
  },

  listWalletTransactionsSuccess: (data) => {
    return {
      type: actions.LIST_WALLET_TRANSACTIONS_SUCCESS,
      data,
    };
  },

  listWalletTransactionsErr: (err) => {
    return {
      type: actions.LIST_WALLET_TRANSACTIONS_ERR,
      err,
    };
  },

  getWalletBalanceBegin: () => {
    return {
      type: actions.GET_WALLET_BALANCE_BEGIN,
    };
  },

  getWalletBalanceSuccess: (data) => {
    return {
      type: actions.GET_WALLET_BALANCE_SUCCESS,
      data,
    };
  },

  getWalletBalanceErr: (err) => {
    return {
      type: actions.GET_WALLET_BALANCE_ERR,
      err,
    };
  },

  // TOPUP
  listTopupRequestBegin: () => {
    return {
      type: actions.LIST_TOPUP_REQUEST_BEGIN,
    };
  },

  listTopupRequestSuccess: (data) => {
    return {
      type: actions.LIST_TOPUP_REQUEST_SUCCESS,
      data,
    };
  },

  listTopupRequestErr: (err) => {
    return {
      type: actions.LIST_TOPUP_REQUEST_ERR,
      err,
    };
  },

  getTopupRequestDetailsBegin: () => {
    return {
      type: actions.GET_TOPUP_REQUEST_DETAILS_BEGIN,
    };
  },

  getTopupRequestDetailsSuccess: (data) => {
    return {
      type: actions.GET_TOPUP_REQUEST_DETAILS_SUCCESS,
      data,
    };
  },

  getTopupRequestDetailsErr: (err) => {
    return {
      type: actions.GET_TOPUP_REQUEST_DETAILS_ERR,
      err,
    };
  },

  addManualCreditBegin: () => {
    return {
      type: actions.ADD_MANUAL_CREDIT_BEGIN,
    };
  },

  addManualCreditSuccess: (data) => {
    return {
      type: actions.ADD_MANUAL_CREDIT_SUCCESS,
      data,
    };
  },

  addManualCreditErr: (err) => {
    return {
      type: actions.ADD_MANUAL_CREDIT_ERR,
      err,
    };
  },

  addManualDebtBegin: () => {
    return {
      type: actions.ADD_MANUAL_DEBT_BEGIN,
    };
  },

  addManualDebtSuccess: (data) => {
    return {
      type: actions.ADD_MANUAL_DEBT_SUCCESS,
      data,
    };
  },

  addManualDebtErr: (err) => {
    return {
      type: actions.ADD_MANUAL_DEBT_ERR,
      err,
    };
  },
};

export default actions;
